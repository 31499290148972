import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["audio"];

  initialize() {
    // We implement autoplay functionality here because Turbo Drive has a bug where the html autoplay attribute doesn't work correctly - https://github.com/hotwired/turbo/issues/1017.
    if (this.hasAudioTarget) {
      this.audioTarget.play();
    }
  }
}
