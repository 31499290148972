import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="gist-form"
export default class extends Controller {
  // Targets are references to elements in HTML by name
  static targets = [
    "gistForm",
    "promptInput",
    "sourceUrlsContainer",
    "sourceUrlInput",
    "sourceUrlInputTemplate",
    "addSourceUrlButton",
    "inputTypePromptButton",
    "inputTypeSourceUrlsButton",
    "createPodcastButton",
  ];

  static values = {
    mode: String,
  };

  connect() {
    super.connect();
    this.updateCreatePodcastButtonState();
  }

  /**
   * Event handler for form submission
   * @param event
   */
  submitOnCmdEnter(event) {
    let pressedCtrl = event.metaKey || event.ctrlKey;
    if (pressedCtrl && event.key === "Enter") {
      event.preventDefault();
      this.gistFormTarget.requestSubmit();
    }
  }

  /**
   * Event handler for input element changes (textarea and url inputs)
   * @param event
   */
  inputValuesChanged(event) {
    event.preventDefault();
    this.updateCreatePodcastButtonState();
  }

  /**
   * Click event handler. Adds a new source URL input row to the form
   * @param event
   */
  addSourceUrlInput(event) {
    event.preventDefault();
    const inputs = this.sourceUrlInputTargets;
    if (inputs.length > 5) {
      return;
    }

    const newInput = this.sourceUrlInputTemplateTarget.content.cloneNode(true);
    const inputContainer = inputs[0].parentElement;
    inputContainer.appendChild(newInput);
    this.updateAddSourceUrlButtonState();
  }

  /**
   * Click event handler. Removes a source URL input row from the form
   * @param event
   */
  removeSourceUrlInput(event) {
    event.preventDefault();
    const toRemove = event.currentTarget.parentElement;
    toRemove.remove();
    this.updateAddSourceUrlButtonState();
  }

  updateAddSourceUrlButtonState() {
    const inputs = this.sourceUrlInputTargets;
    this.addSourceUrlButtonTarget.disabled = inputs.length >= 5;
  }

  /**
   * Updates the UI state of the create podcast button (submit button)
   */
  updateCreatePodcastButtonState() {
    const prompt = this.promptInputTarget.value;
    let validUrlInputs = this.sourceUrlInputTargets
      .map((div) => div.querySelector("input").value.trim())
      .filter((url) => url.startsWith("http"));

    if (!prompt && validUrlInputs.length === 0) {
      this.createPodcastButtonTargets.forEach((div) => (div.disabled = true));
    } else {
      this.createPodcastButtonTargets.forEach((div) => (div.disabled = false));
    }
  }

  /**
   * Click event handler. Toggles between prompt and source URLs input
   * @param event
   */
  toggleInputType(event) {
    this.gistFormTarget.reset();

    // selectedType is either "prompt" or "source_urls"
    const selectedType = event.params.selectedType;
    // if selected type is currently active, do nothing
    if (this.selectedInputTypeValue === selectedType) {
      return;
    }

    this.selectedInputTypeValue = selectedType;
    const isPrompt = selectedType === "prompt";
    const isSourceUrls = selectedType === "source_urls";
    this.inputTypePromptButtonTarget.classList.toggle(
      "button--selected",
      isPrompt,
    );
    this.inputTypeSourceUrlsButtonTarget.classList.toggle(
      "button--selected",
      isSourceUrls,
    );
    this.promptInputTarget.classList.toggle("d-none", !isPrompt);
    this.sourceUrlsContainerTarget.classList.toggle("d-none", !isSourceUrls);

    this.updateCreatePodcastButtonState();
  }

  /**
   * Click event handler. Prefills input elements based on the quick add button clicked
   * @param event
   */
  quickAdd(event) {
    this.gistFormTarget.reset();
    const button = event.currentTarget;
    const template = button.dataset.template;

    // if the template is url, add it to the source urls input
    if (template.startsWith("http")) {
      event.params.selectedType = "source_urls";
      this.toggleInputType(event);
      this.sourceUrlInputTargets[0].querySelector("input").value = template;
    } else {
      event.params.selectedType = "prompt";
      if (this.modeValue !== "compact") {
        this.toggleInputType(event);
      }
      this.promptInputTarget.value = template;
    }

    this.updateCreatePodcastButtonState();
  }
}
